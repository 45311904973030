import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VorlageModel } from '@dworkflow/shared/model/vorlagen/vorlage.model';
import { guid } from '@dworkflow/shared/utility/guid';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { VorlagenType, VorlagenkonfigurationModel } from '../model';
import { VorlageWriteModel } from '../model/vorlagen/vorlage-write.model';
import { VorlagenLadeType } from '../model/vorlagen/vorlagen-lade-type.enum';
import { KonfigurationService } from './konfiguration.service';

@Injectable({
  providedIn: 'root',
})
export class VorlagenService {
  constructor(private http: HttpClient, private konfigService: KonfigurationService) {}

  public createVorlage(
    vorlage: VorlageWriteModel,
    vorlagenLadeTyp: VorlagenLadeType
  ): Observable<VorlageModel> {
    const apiUrl = this.buildApiUrl(environment.engine.vorlagenEndpoint, vorlagenLadeTyp);
    const vorlagenPayload = this.serializeVorlageWriteModel(vorlage);

    return this.http
      .post<VorlageModel>(apiUrl, vorlagenPayload)
      .pipe(map(v => this.deserializeVorlageModel(v)));
  }

  public updateVorlage(
    vorlage: VorlageWriteModel,
    vorlagenLadeTyp: VorlagenLadeType
  ): Observable<VorlageModel> {
    const apiUrl = this.buildApiUrl(environment.engine.vorlagenEndpoint, vorlagenLadeTyp);
    const vorlagenPayload = this.serializeVorlageWriteModel(vorlage);

    return this.http
      .patch<VorlageModel>(apiUrl, vorlagenPayload)
      .pipe(map(v => this.deserializeVorlageModel(v)));
  }

  public deleteVorlage(id: guid, vorlagenType: VorlagenLadeType): Observable<unknown> {
    const apiUrl = this.buildApiUrl(environment.engine.vorlagenEndpoint, vorlagenType);
    return this.http.delete(`${apiUrl}/${id}`);
  }

  public kopiereVorlage(
    vorlagenId: guid,
    kopierenNachVorlagenTyp: VorlagenType
  ): Observable<VorlageModel> {
    return this.http
      .post<VorlageModel>(`${environment.engine.vorlagenEndpoint}/kopie`, {
        originalVorlagenId: vorlagenId,
        neuerVorlagenTyp: kopierenNachVorlagenTyp,
      })
      .pipe(map(v => this.deserializeVorlageModel(v)));
  }

  public getVorlagen(vorlagenLadeType: VorlagenLadeType): Observable<VorlageModel[]> {
    const apiUrl = this.buildApiLoadUrl(environment.engine.vorlagenEndpoint, vorlagenLadeType);
    return this.http
      .get<VorlageModel[]>(apiUrl)
      .pipe(map(vs => vs?.map(v => this.deserializeVorlageModel(v))));
  }

  public getVorlagenZumWfAnlegen(): Observable<VorlageModel[]> {
    return this.http
      .get<VorlageModel[]>(`${environment.engine.vorlagenEndpoint}/wfanlegen`)
      .pipe(map(vs => vs.map(v => this.deserializeVorlageModel(v))));
  }

  getVorlage(id: guid, rollenAufloesen: boolean): Observable<VorlageModel> {
    let params = new HttpParams();
    const resolveRolesString = rollenAufloesen ? 'true' : 'false';
    params = params.set('resolveRoles', resolveRolesString);
    return this.http
      .get<VorlageModel>(`${environment.engine.vorlagenEndpoint}/${id}`, { params })
      .pipe(map(v => this.deserializeVorlageModel(v)));
  }

  getVorlagenExport(vorlagenId: guid): Observable<unknown> {
    return this.http.get<unknown>(`${environment.engine.vorlagenEndpoint}/${vorlagenId}/export`);
  }

  public vorlageFreigeben(principalIds: number[], vorlagenId: string): Observable<unknown> {
    return this.http.put(
      `${environment.engine.vorlagenEndpoint}/${vorlagenId}/freigeben`,
      principalIds
    );
  }

  public importVorlage(vorlagenFileContent: string): Observable<{
    vorlage: VorlageModel;
    konfiguration: VorlagenkonfigurationModel;
  }> {
    return this.http
      .post<{
        vorlage: VorlageModel;
        konfiguration: VorlagenkonfigurationModel;
      }>(`${environment.engine.vorlagenEndpoint}/import`, JSON.parse(vorlagenFileContent))
      .pipe(
        switchMap(({ konfiguration, vorlage }) =>
          this.konfigService.loadSchrittKonfigurationIntoKonfiguration(konfiguration).pipe(
            map(konfig => ({
              konfiguration: konfig,
              vorlage: this.deserializeVorlageModel(vorlage),
            }))
          )
        )
      );
  }

  buildApiUrl(templatesBaseUrl: string, vorlagenLadeType: VorlagenLadeType): string {
    let suffix;
    switch (vorlagenLadeType) {
      case VorlagenLadeType.Persoenliche:
      case VorlagenLadeType.AllePersoenlichen:
        suffix = '/persoenlich';
        break;
      case VorlagenLadeType.Allgemeine:
        suffix = '';
        break;
      case VorlagenLadeType.Freigegebene:
        suffix = '/alle';
        break;
      case VorlagenLadeType.Schnittstelle:
        suffix = '/schnittstelle';
        break;
      default:
        throw new Error(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `Create/Update/Delete für Typ ${vorlagenLadeType} Vorlagen nicht implementiert`
        );
    }
    return `${templatesBaseUrl}${suffix}`;
  }

  buildApiLoadUrl(templatesBaseUrl: string, vorlagenLadeType: VorlagenLadeType): string {
    let suffix;
    switch (vorlagenLadeType) {
      case VorlagenLadeType.Persoenliche:
        suffix = '/persoenlich';
        break;
      case VorlagenLadeType.AllePersoenlichen:
        suffix = '/persoenlich/alle';
        break;
      case VorlagenLadeType.Allgemeine:
        suffix = '';
        break;
      case VorlagenLadeType.Freigegebene:
        suffix = '/freigegeben';
        break;
      case VorlagenLadeType.Schnittstelle:
        suffix = '/schnittstelle';
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Load für Typ ${vorlagenLadeType} Vorlagen nicht implementiert`);
    }
    return `${templatesBaseUrl}${suffix}`;
  }

  private deserializeVorlageModel(vorlage: VorlageModel): VorlageModel {
    return {
      ...vorlage,
      vorlagendaten: {
        ...vorlage.vorlagendaten,
        phasen: [
          ...vorlage.vorlagendaten.phasen.map(p => ({
            ...p,
            schritte: p.schritte.map(s => ({
              ...s,
              zusaetzlicheEigenschaften: JSON.parse(
                s.zusaetzlicheEigenschaften as string
              ) as unknown,
            })),
          })),
        ],
      },
    };
  }

  private serializeVorlageWriteModel(vorlage: VorlageWriteModel): VorlageWriteModel {
    return {
      ...vorlage,
      vorlagendaten: {
        ...vorlage.vorlagendaten,
        phasen: [
          ...vorlage.vorlagendaten.phasen.map(p => ({
            ...p,
            schritte: p.schritte.map(s => ({
              ...s,
              zusaetzlicheEigenschaften: JSON.stringify(s.zusaetzlicheEigenschaften),
            })),
          })),
        ],
      },
    };
  }
}
